import React from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import '../styles/custom.scss';

import HomePage from './home';
class App extends React.Component {
  
  render() {
    return(
      <>
        <HomePage />
      </>
    );
  }

}

export default App;
